<template>
  <div class="content">
    <h2>用户协议</h2>
    <div v-html="text"></div>
  </div>
</template>
<script>
import {
  getContentInfo, //内容
} from "@/api/index.js";
export default {
  name: "Useragreement",
  data() {
    return {
      text: "32121",
      resData: {},
    };
  },
  mounted() {
    this.personal();
  },
  methods: {
    // 个人协议
    async personal() {
      let res = await getContentInfo({ contentType: 5, type: 3 });
      console.log(res);
      if (res.code == 1) {
        // this.aboutText = res.data;
        let resData = res.data || {};
        this.text = resData.content || "";
        // this.contentImg = resData.articleImage
        //   ? "/com/file/fileDownLoad?filePath=" + resData.articleImage
        //   : "";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 1200px;
  margin: 0 auto;
  line-height: 2;
}
h2 {
  margin-top: 30px;
  text-align: center;
}
</style>