import { render, staticRenderFns } from "./Useragreement.vue?vue&type=template&id=53641ddc&scoped=true"
import script from "./Useragreement.vue?vue&type=script&lang=js"
export * from "./Useragreement.vue?vue&type=script&lang=js"
import style0 from "./Useragreement.vue?vue&type=style&index=0&id=53641ddc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53641ddc",
  null
  
)

export default component.exports